<template>
  <div class="my-account">
    <div class="header-menu va-row">
      <div class="va-row">
        <div class="flex container-ddh-menu xs12 with-bottom">
          <h1>Escola</h1>
        </div>
        <!-- <menu-tabs
            :items="[
              {'label':'Dados Pessoais', 'slug':'my-data', 'isActive': false},
              {'label':'Meus Endereços', 'slug':'adresses', 'isActive': true},
              {'label':'Meus Cartões', 'slug':'cards', 'isActive': false}
            ]"
            @scrollTo="scrollParent"
            ref="tabs" class="container-ddh-menu">
          </menu-tabs> -->
      </div>
      <div class="clearfix"></div>
    </div>

    <div class="container-dhh va-row">
      <div class="flex md12 xs12 no-padding">
        <h4>Escola</h4>
      </div>

      <div class="flex md8 xs12 no-padding">
        <div class="ui-grid">
          <div class="my-data-table va-row" id="cards">
            <div class="flex xs12 no-padding fiscal-notes">
              <div class="body-spacing">
                <div class="va-row">
                  <div class="error-label" v-if="errors.length > 0">
                    {{ errors[0].message }}
                  </div>

                  <form @submit.prevent="saveSchool" class="va-row">
                    <!-- {{isCreation}} -->

                    <!-- {{school}}
                      {{typeSend}} -->

                    <div class="flex xs12 md12 no-padding">
                      <input-normal
                        type="text"
                        label="Node Usuário"
                        name="label"
                        :required="'true'"
                        :value="user.username"
                        :disabled="false"
                        @input="user.username = $event.target.value"
                      />

                      <input-normal
                        type="password"
                        label="Senha"
                        name="label"
                        :required="'true'"
                        :value="''"
                        :disabled="false"
                        @input="user.password = $event.target.value"
                      />

                      <input-normal
                        type="text"
                        label="Data de Nascimento"
                        name="label"
                        :required="'true'"
                        :value="user.birthdate"
                        :disabled="false"
                        @input="user.birthdate = $event.target.value"
                      />

                      <input-normal
                        type="text"
                        label="E-mail"
                        name="label"
                        :required="'true'"
                        :value="user.email"
                        :disabled="false"
                        @input="user.email = $event.target.value"
                      />

                      <input-normal
                        type="text"
                        label="name"
                        name="label"
                        :required="'true'"
                        :value="user.name"
                        :disabled="false"
                        @input="user.name = $event.target.value"
                      />

                      <input-normal
                        type="text"
                        label="Telefone de contato"
                        name="label"
                        :required="'true'"
                        :value="school.phone"
                        :disabled="false"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        @input="school.phone = $event.target.value"
                      />

                      <select-normal
                        :items="[
                          { key: 'Masculino', value: 'M' },
                          { key: 'Feminino', value: 'F' },
                        ]"
                        :value="user.gender"
                        label="Selecione um projeto"
                        name="typeName"
                        @change="onChangeProject($event)"
                      />

                      <!-- <input-normal type="text" 
                            label="name" 
                            name="label" 
                            :required="'true'" 
                            :value="user.name" 
                            :disabled="false" 
                            
                            @input="user.name = $event.target.value" 
                            /> -->

                      <div class="flex xs12 no-padding">
                        <div class="facebook-tag">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            viewBox="0 0 60 60"
                          >
                            <path
                              fill="#000"
                              fill-rule="evenodd"
                              d="M32.385 46V32.315h4.723l.702-5.33h-5.425v-3.407c0-1.54.44-2.595 2.712-2.595H38v-4.774c-.5-.07-2.225-.209-4.223-.209-4.187 0-7.042 2.491-7.042 7.057v3.94H22v5.33h4.735V46h5.65z"
                            />
                          </svg>
                          <span class="name">Deseja receber emails?</span>
                          <div class="facebook-toggle">
                            <toggle-button
                              :value="myAccount.facebookEnabled"
                              @change="onChangeEventHandler"
                              :switch-color="{
                                checked: '#ffffff',
                                unchecked: '#ff0000',
                              }"
                              :color="{
                                checked: '#1b3362',
                                unchecked: '#ffffff',
                                disabled: '#CCCCCC',
                              }"
                              :width="66"
                              :height="30"
                              :sync="true"
                              :labels="false"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="flex xs12 no-padding">
                        <div class="facebook-tag">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            viewBox="0 0 60 60"
                          >
                            <path
                              fill="#000"
                              fill-rule="evenodd"
                              d="M32.385 46V32.315h4.723l.702-5.33h-5.425v-3.407c0-1.54.44-2.595 2.712-2.595H38v-4.774c-.5-.07-2.225-.209-4.223-.209-4.187 0-7.042 2.491-7.042 7.057v3.94H22v5.33h4.735V46h5.65z"
                            />
                          </svg>
                          <span class="name">Gerar senha automática?</span>
                          <div class="facebook-toggle">
                            <toggle-button
                              :value="myAccount.facebookEnabled"
                              @change="onChangeEventHandler"
                              :switch-color="{
                                checked: '#ffffff',
                                unchecked: '#ff0000',
                              }"
                              :color="{
                                checked: '#1b3362',
                                unchecked: '#ffffff',
                                disabled: '#CCCCCC',
                              }"
                              :width="66"
                              :height="30"
                              :sync="true"
                              :labels="false"
                            />
                          </div>
                        </div>
                      </div>

                      <select-normal
                        :items="[
                          { key: 'Masculino', value: 'M' },
                          { key: 'Feminino', value: 'F' },
                        ]"
                        :value="user.gender"
                        label="Selecione um gênero"
                        name="typeName"
                        @change="onChangeProject($event)"
                      />
                    </div>

                    <hr />

                    <div class="flex md12 text-right">
                      <button type="submit" class="btn btn-primary right">
                        SALVAR EDIÇÃO
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="flex md3 xs12 subscription-box" v-if="subscriptionsData">
              <div class="panel newsletter">
                Deseja utilizar esse endereço no Clube?<br>

                  <div v-for="(subscriptionItem, index) in subscriptionsData" :key="index">


                    <RadioButton class="box-payment box-method" :value="false" :selected="getSelectedId(subscriptionItem.id)" @click="selectSubscription(subscriptionItem.id, index)">
                      <div>{{subscriptionItem.characterName}}</div>
                    </RadioButton>
                  </div>

                  <div v-if="subscriberEditing" class="editing">
                    <button type="submit" class="btn btn-primary right" v-on:click="saveAddress()">SALVAR EDIÇÃO</button>
                  </div>

              </div>
            </div> -->
  </div>
</template>

<script>
// import { required, minLength, between } from 'vuelidate/lib/validators'
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

const axios = require("axios");

export default {
  name: "school",
  directives: { mask },
  components: {
    // vuescroll
    // VueScrollTo
    // DataVisualisationTab,
  },

  props: ["school", "typeSend"],

  data() {
    return {
      openAddressField: false,
      cepSearch: false,
      types: [
        {
          key: 1,
          value: "Pública",
        },
        {
          key: 2,
          value: "Particular",
        },
        {
          key: 3,
          value: "Autônoma",
        },
      ],
      projects: [
        {
          key: 1,
          value: "Projeto 1",
        },
        {
          key: 2,
          value: "Projeto 2",
        },
        {
          key: 3,
          value: "Projeto 3",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      userCredential: "userCredential",
      // ...
    }),
  },

  mounted() {
    const self = this;
  },

  //
  methods: {
    onChangeType(event) {
      this.school.type = event.target.value;
    },

    onChangeProject(event) {
      this.school.project_id = event.target.value;
    },

    onChangeState(event) {
      this.school.address.stateId = event.target.value;
    },

    created: function () {
      const self = this;

      if (
        this.school.address.zip_postal_code.length === 9 &&
        self.cepSearch === false
      ) {
        self.cepSearch = true;

        axios
          .get(
            `https://api.pagar.me/1/zipcodes/${this.school.address.zip_postal_code}`
          )
          .then((res) => {
            this.school.address.state = res.data.state;
            this.school.address.city = res.data.city;
            this.school.address.district = res.data.neighborhood;
            this.school.address.address1 = res.data.street;

            if (res.data.street === undefined) {
              this.openAddressField = true;
            }

            self.cepSearch = false;
          })
          .catch((error) => {
            this.openAddressField = true;
            self.cepSearch = true;

            //console.log(error)
          });
      }
    },

    updateAndVerifyCep({ e, target }) {
      this.school.address.zip_postal_code = target.value;
      this.created();
    },

    selectSubscription(id, index) {
      this.subscriberEditing = true;
      let subscriptionId = { id: id };

      // alert(this.subscriptionsData[index].shippingAddressId);
      // this.subscriptionsData[index].shippingAddressId = this.address.id

      var index = this.subscriptionSend.subscriptions.indexOf(
        this.subscriptionSend.subscriptions.find((c) => {
          return c.id === id;
        })
      );
      if (index > -1) {
        this.subscriptionSend.subscriptions.splice(index, 1);
      } else {
        this.subscriptionSend.subscriptions.push(subscriptionId);
      }
    },

    getSelectedId(id) {
      let response = false;

      let objectId = this.subscriptionSend.subscriptions.find((c) => {
        return c.id === id;
      });
      if (objectId) {
        if (objectId.id === id) {
          response = true;
        }
      }

      // if(id == this.address.id){
      //   response = true
      // }

      return response;
    },

    deleteAddress() {
      const self = this;

      axios
        .delete(
          process.env.VUE_APP_ROOT_API + "/customer/address/" + this.address.id
        )
        .then((response) => {
          self.$router.push({ name: "adresses" });
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    saveSchool() {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.userCredential.token,
        },
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          // eslint-disable-next-line
          let url =
            self.typeSend == "put"
              ? process.env.VUE_APP_ROOT_API + "/schools/" + self.school.id
              : process.env.VUE_APP_ROOT_API + "/schools/";

          let data = this.school;
          if (self.typeSend == "put") {
            data = {
              name: this.school.name,
              address: this.school.address,
              contact_name: this.school.contact_name,
              contact_email: this.school.contact_email,
              contact_phone: this.school.contact_phone,
              type: this.school.type,
            };

            delete data.address.created_at;
          }

          axios({
            method: self.typeSend.toUpperCase(),
            url: url,
            headers: config.headers,
            data: data,
          })
            .then(function (response) {
              if (response.data.success !== false) {
                self.$router.push({ name: "schools" });
                //self.percentCall = 0
              } else {
                self.errors.push(response.data.errors[0]);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },

    showSubscriberEditing() {
      this.subscriberEditing = false;
    },
    hideSubscriberEditing() {
      this.subscriberEditing = false;
    },

    scrollParent(slug) {
      this.$router.push({ name: slug });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 580px) {
  .btn-primary {
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 5px;
    }
  }
}

.fiscal-notes ::v-deep .form-group:last-child {
  margin-bottom: 10px !important;
}

.error-label {
  color: red;
  text-align: left;
  margin-bottom: 10px;
}
small {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-indent: 15px;
  margin-top: -5px;
  display: block;
  margin-bottom: 10px;
  // padding-left: 15px;
}
h5 {
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
}
.address-info {
  margin-bottom: 15px;
}
.editing {
  margin-top: 15px;
}
.radio-button-wrapper {
  padding: 14px 11px;
  margin-top: 10px;

  @media (max-width: 990px) {
    padding: 20px 19px;
    margin-top: 10px;
  }
}
.header-menu-club {
  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}
.add-new-card {
  border-radius: 10px;
  border: dashed 2px #2cd3d8;
  background-color: #ffffff;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.5;
  letter-spacing: 0.7px;
  text-align: center;
  color: #4965bc;
  margin-bottom: 20px;
  padding: 15px 0px;
  cursor: pointer;

  .plus {
    font-family: "Nunito";
    font-size: 35px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    width: 40px;
    height: 40px;
    transform: rotate(-180deg);
    border-radius: 40px;
    border: solid 1px #4965bc;
    background-color: #4965bc;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    line-height: 36px;
  }
}

// Default of page

h4 {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
  margin-bottom: 0;
}
.header-menu {
  background-color: white;

  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}
.my-data {
  /* Facebook bar */
  .facebook-tag {
    height: 65px;
    border-radius: 5px;
    background-color: #3a5998;
    color: white;
    padding: 14px 20px;
    margin-bottom: 20px;

    svg {
      font-size: 26px;
      margin-right: 0px;
      float: left;

      path {
        fill: #fff;
      }
    }

    .name {
      display: inline-block;
      margin-top: 1px;
      line-height: 37px;
    }

    .facebook-toggle {
      float: right;
      display: block;
      margin-top: 5px;
    }
  }
}

.change-password {
  border-radius: 10px;
  border: solid 2px #f0ebf5;
  background-color: #ffffff;
  padding: 24px 20px;
  position: relative;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 7px;
    font-family: "Roboto";

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  h5 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #afa2ba;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .floated-icon {
    float: left;
    width: 36px;
    height: 36px;
    font-size: 30px;

    svg {
      position: relative;
      top: -5px;
      left: -3px;

      path {
        fill: #afa2ba;
      }
    }
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }
}

.my-data-table {
  h4 {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
.fiscal-notes {
  border-radius: 10px;
  border: solid 1px #f0ebf5;
  background-color: #ffffff;
  position: relative;
  border: solid 2px #f0ebf5;

  h4 {
    font-size: 16px;
    color: #68596e !important;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .body-spacing {
    padding: 30px 30px 10px 30px;

    @media (max-width: 990px) {
      padding: 25px 15px 15px 15px;
    }

    //  div:last-child{
    //   margin-bottom: 0px;
    // }
  }

  hr {
    border-top: solid 1px #f0ebf5;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  .company-code {
    text-transform: capitalize;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  // .panel:last-child{
  //   margin-bottom: 0px;
  // }
}

.address-boxes {
  border-radius: 10px;
  border: solid 1px #f0ebf5;
  background-color: #ffffff;
  position: relative;
  border: solid 2px #f0ebf5;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  svg {
    path {
      fill: #afa2ba;
    }
  }

  .clube {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .body-spacing {
    padding: 30px 30px;

    @media (max-width: 990px) {
      padding: 25px 15px 15px 15px;
    }
  }

  hr {
    border-top: solid 2px #f0ebf5;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }

  .address-row {
    padding: 16px 0px;
    position: relative;
    border-bottom: solid 1px #f0ebf5;
    b {
      font-size: 16px;
      color: #000000;
    }
    :last-child {
      border-bottom: none;
    }
  }
}

.address-row {
  position: relative;
}

.more-cards {
  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }
}

.panel {
  // height: 200px;
  border-radius: 10px;
  border: solid 2px #9378f0;
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 20px;

  .header-panel {
    padding: 0px 20px;
    background-color: #9378f0;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .clube img {
    margin-top: -5px;
  }

  b {
    font-size: 16px;
    color: #000000;
  }

  .panel-body {
    position: relative;
    padding: 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #afa2ba;
    margin: 0px !important;

    .flex.xs1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 6.33333%;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 6.33333%;
    }

    b {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .editor {
      right: 18px;
      top: 20%;
    }
  }
}

.subscription-box {
  // padding: 30px !important;

  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #000000;

  .toggled {
    margin-top: 10px;
  }
}
.newsletter {
  // margin-top:40px;
  padding: 31px;
  border: solid 2px #f0ebf5;

  @media (max-width: 990px) {
    margin-top: 0px;
    padding: 15px;
  }
}

// Custom Component
.v-switch-button {
  ::v-deep &:before {
    content: "";
    display: block;
    position: relative;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 14.5px;
    margin-left: auto;
    margin-right: auto;
    top: 9px;
  }
}

.vue-js-switch {
  &.toggled {
    .v-switch-button {
      ::v-deep &:before {
        content: "";
        display: block;
        position: relative;
        background-color: #7ed321;
        width: 6px;
        height: 6px;
        border-radius: 14.5px;
        margin-left: auto;
        margin-right: auto;
        top: 9px;
      }
    }
  }
}

.name-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
.lastname-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
</style>
